import React, { Component } from 'react'

import StagePage from '../../containers/StagePage'
import PrimaryLayout from '../../components/layouts/PrimaryLayout'
import pagesInfos from '../../config/i18n/pagesInfos'

const Stages = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentCyclesEtStagesStages}
    >
      <StagePage stage="2019-2020" />
    </PrimaryLayout>
  )
}

export default Stages
