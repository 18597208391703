import React, { useState } from 'react'
import Img from 'gatsby-image'
import { Link, Element } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Club from '../components/Club'
import Form from '../components/Form'
import Button from '../components/Button'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  image: {
    display: 'block',
    position: 'absolute !important',
    bottom: -150,
    right: 0,
    zIndex: 2,
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sectionContainer: {
    position: 'relative',
  },
}))

const chunk = (arr, len) => {
  var chunks = [],
    i = 0,
    n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

const StagePage = ({ stage }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "tenniswoman1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const baseTranslationKey = 'stages:' + stage
  var titleLabel = ""
  const Dates = chunk(
    t(baseTranslationKey + '.dates.content', {
      returnObjects: true,
    }),
    t(baseTranslationKey + '.dates.content', {
      returnObjects: true,
    }).length
  ).map((dates, key) => {
    return (
      <Grid item xs={12} sm={12} key={key.toString()}>
        <ul>
          {
          dates.map((date, key) => {
            if(titleLabel == date.title)
            {
              titleLabel = date.title
              const labelSplit = date.label.split(':')
              return (
                <li key={'date' + key.toString()}>
                  <strong>{labelSplit[0]} : </strong>
                  {labelSplit[1]}
                </li>
              )
            }
            else
            {
              titleLabel = date.title
              const labelSplit = date.label.split(':')
              if(titleLabel == "")
              {
                return (
                  <div>
                  <br></br>
                  <h2>{date.title}</h2>
                  <li key={'date' + key.toString()} style={{size: '8px'}}>
                    <strong>{labelSplit[0]} : </strong>
                    {labelSplit[1]}
                  </li>
                  </div>
                )
              }
              else
              {
                return (
                  <div>
                  <h2>{date.title}</h2>
                  <li key={'date' + key.toString()} style={{size: '8px'}}>
                    <strong>{labelSplit[0]} : </strong>
                    {labelSplit[1]}
                  </li>
                  </div>
                )
              }
              
            }
            
            
          })
          }
        </ul>
      </Grid>
    )
  })

  return (
    <React.Fragment>
      <Helmet
        title={t(baseTranslationKey + '.head.title')}
        meta={[
          {
            name: 'description',
            content: t(baseTranslationKey + '.head.description'),
          },
        ]}
      />

      <Section bg="#fafcff" className={classes.sectionContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Typography
              component="h1"
              variant="h2"
              className={classes.title}
              gutterBottom
            >
              {t(baseTranslationKey + '.introduction.title')}
            </Typography>
            <Heading title="Introduction" variant="secondary" />
            {t(baseTranslationKey + '.introduction.content', {
              returnObjects: true,
            }).map((text, key) => (
              <p key={key.toString()}>{text}</p>
            ))}
            <Grid item container xs={12} spacing={3} className={classes.button}>
              <Grid item xs={12} sm={6}>
                <Link to="formulaire" smooth={true} duration={500}>
                  <Button fullWidth>
                    {t(baseTranslationKey + '.introduction.button')}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  variant="secondary"
                  fullWidth
                  target="_blank"
                  href="http://www.stagestennis.be"
                  rel="noopener noreferrer nofollow"
                >
                  www.stagestennis.be
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Img
          fluid={data.backgroundImage.childImageSharp.fluid}
          className={classes.image}
        />
      </Section>
      
      <Section bg="#E7F1FE">
        <Heading title={t(baseTranslationKey + '.dates.title')} variant="secondary" />
        <Grid container spacing={2}>
          {Dates}
        </Grid>
      </Section>

      <Section bg="#fafcff">
        <Heading title="Clubs" variant="secondary" />
        <Grid container spacing={2}>
          <Grid item sm={3} xs={12}>
            <Club club={t('clubs:royal', { returnObjects: true })} />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Club club={t('clubs:churchill', { returnObjects: true })} />
          </Grid>
          {/* 
              <Grid item sm={3} xs={12}>
            <Club club={t('clubs:leopold', { returnObjects: true })} />
          </Grid>
          
          */}
          <Grid item sm={3} xs={12}>
            <Club club={t('clubs:montjoie', { returnObjects: true })} />
          </Grid>
        </Grid>
      </Section>

      <Element id="formulaire" name="formulaire">
        <Section bg="#E7F1FE">
          <Heading title={t('form:titles.stage')} />
          <Form
            variant="stage"
            stages={t(baseTranslationKey + '.dates.content', {
              returnObjects: true,
            })}
          />
        </Section>
      </Element>
    </React.Fragment>
  )
}

StagePage.defaultProps = {}

export default StagePage
